import React, { useState } from "react";
import axios from "axios";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import "./App.css";

function App(): JSX.Element {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<{ input: string; output: string }[]>(
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MESSAGE_SERVER}/process`,
        { input }
      );
      const newMessage = { input, output: response.data };
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    setInput("");
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="input"
            label="Enter your input"
            value={input}
            onChange={(event) => setInput(event.target.value)}
            fullWidth
            margin="normal"
            sx={{ width: "96%" }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
          >
            Submit
          </Button>
        </Box>
      </form>
      {loading && <CircularProgress sx={{ marginTop: "20px" }} />}
      <Box sx={{ mt: 5, maxWidth: "50%" }}>
        {messages.map((message, index) => (
          <Box key={index} sx={{ display: "flex", mb: 2 }}>
            <Box
              sx={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#f3f3f3",
                minWidth: "200px",
              }}
            >
              <div>{message.input}</div>
              <div style={{ fontWeight: "bold", marginTop: "10px" }}>
                {message.output}
              </div>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default App;
